import Card from "../../common/Card";
import React from "react";
import message from "../../../message";
import {SERVICE} from "../../../service";
import {useLoaderData} from "react-router-dom";
import notify from "../../../notify";

export default function GameSidebarNotes({notes, updNotifications}) {
  return <>
    {
      notes && notes.length > 0 ?
        <Card title={<h3 className="text-decoration-none fw-bold">{message("page.game.card.notes.title")}</h3>}>
          <ul className="list-group my-1">
            {
              notes.map(note =>
                <GameSidebarNote key={note.id} note={note} updNotifications={updNotifications}/>
              )
            }
          </ul>
        </Card>
        : <></>
    }
  </>
}

export function GameSidebarNote({note, updNotifications}) {

  const gameId  = useLoaderData().game.id;

  async function updateNote(noteId) {
  }

  async function removeNote(noteId) {
    SERVICE.removeNote(gameId, noteId)
      .then(res => {
        if (res.status === 403) {
          notify(updNotifications, "error", message("notification.game.note.remove.forbidden"))
        }
      })
  }

  return (
    <>
      <li className="list-group-item justify-content-between align-items-start border-secondary">

        <div className="d-flex">
          <span className="col">
          <span className="badge bg-gradient text-dark">{getTime(note.timestamp)}</span>
          <span className="badge bg-gradient text-dark">{note.username}</span>
          </span>

          <div className="d-flex">
            {/*{*/}
            {/*  note.edited ?*/}
            {/*    <button className="btn p-0" onClick={() => updateNote(note.id)}>*/}
            {/*      <i className="bi bi-pencil-fill fs-6 mx-1"></i>*/}
            {/*    </button>*/}
            {/*    :*/}
            {/*    <button className="btn p-0" onClick={() => updateNote(note.id)}>*/}
            {/*      <i className="bi bi-pen fs-6 mx-1"></i>*/}
            {/*    </button>*/}
            {/*}*/}
            <button className="btn p-0" onClick={() => removeNote(note.id)}>
              <i className="bi bi-trash fs-6 mx-1"></i>
            </button>
          </div>
        </div>

        <hr className="my-0"/>

        <p className="ms-2 text-start">{note.content}</p>
      </li>
    </>
  );
}

function getTime(timestamp) {
  return getHours(timestamp) + ":" + getMinutes(timestamp) + ":" + getSeconds(timestamp)
}

function getHours(timestamp) {
  return (new Date(Date.parse(timestamp)).getHours() < 10 ? '0' : '') + new Date(Date.parse(timestamp)).getHours();
}

function getMinutes(timestamp) {
  return (new Date(Date.parse(timestamp)).getMinutes() < 10 ? '0' : '') + new Date(Date.parse(timestamp)).getMinutes();
}

function getSeconds(timestamp) {
  return (new Date(Date.parse(timestamp)).getSeconds() < 10 ? '0' : '') + new Date(Date.parse(timestamp)).getSeconds();
}
