import message from "../../message";
import ResourceInput from "./ResourceInput";
import React from "react";
import ResourcePreview from "./ResourcePreview";
import Card from "../common/Card";

export function ResourcesCard({resources, updateScenario}) {
    return <>
        <Card title={<ResourceCardTitle/>}>
            {
                resources && resources.length > 0
                    ?
                    <div className="card shadow-sm mt-0 pt-0">
                        <div className="card-body">
                            {resources.map(res => <ResourcePreview key={res.id} id={res.id} name={res.name}
                                                                   updateScenario={updateScenario}/>)}
                        </div>
                    </div>
                    : <></>
            }
            <ResourceInput updateScenario={updateScenario}/>
    </Card>
  </>
}

function ResourceCardTitle() {
    return (<>
        <h5 className="align-items-center text-center">
            {message("page.editor.card.form.resources.title")}
        </h5>
    </>);
}
