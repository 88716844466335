import 'bootstrap-icons/font/bootstrap-icons.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React from 'react';
import ReactDOM from 'react-dom/client'
import {createBrowserRouter, RouterProvider} from "react-router-dom";

import Layout from './containers/Layout'
import MainLayout from './containers/Layout'
import ErrorContainer from "./containers/ErrorContainer";
import LobbyContainer from "./containers/LobbyContainer";
import EditorContainer from "./containers/EditorContainer";
import ScenariosContainer from "./containers/ScenariosContainer";
import StartContainer from "./containers/StartContainer";
import LoginContainer from "./containers/LoginContainer";
import GameContainer from "./containers/GameContainer";
import RegistrationContainer from "./containers/RegistrationContainer";
import {userLoader} from "./loaders/userLoader";
import {scenariosLoader} from "./loaders/scenariosLoader";
import {scenarioLoader} from "./loaders/scenarioLoader";
import {lobbyLoader} from "./loaders/lobbyLoader";
import gameLoader from "./loaders/gameLoader";
import {startLoader} from "./loaders/startLoader";

const router = createBrowserRouter([
  {
    element: <Layout/>,
    errorElement: <ErrorContainer/>,
    children: [
      {
        path: "registration",
        element: <RegistrationContainer/>,
      },
      {
        path: "login",
        element: <LoginContainer/>,
      },
    ],
  },
  {
    element: <MainLayout/>,
    loader: userLoader,
    errorElement: <ErrorContainer/>,
    children: [
      {
        path: "/",
        loader: startLoader,
        element: <StartContainer/>,
      },
      {
        path: "/lobby/:lobbyId",
        element: <LobbyContainer/>,
        loader: lobbyLoader,
      },
      {
        path: "/game/:gameId",
        element: <GameContainer/>,
        loader: gameLoader,
      },
      {
        path: "/editor",
        element: <ScenariosContainer/>,
        loader: scenariosLoader,
      },
      {
        path: "/editor/:scenarioId",
        element: <EditorContainer/>,
        loader: scenarioLoader,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root"))
    .render(
        <RouterProvider router={router}/>
    );