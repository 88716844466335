import {NavLink} from "react-router-dom";
import message from "../../message";

export default function AuthCardTitle({current}) {
  return <>
    <ul className="nav justify-content-center tabs">
      <li className="nav-item fs-4 nav-item">
        <NavLink className={"nav-link" + (current === "login" ? " active text-dark" : "")} to="/login">
          <p className={"m-0" + (current === "login" ? " fw-bolder" : "")}>{message("page.login.card.title")}</p>
        </NavLink>
      </li>
      <li className="nav-item fs-4 nav-item">
        <NavLink className={"nav-link" + (current === "registration" ? " active text-dark" : "")}
                 to="/registration">
          <p className={"m-0 " + (current === "registration" ? " fw-bolder" : "")}>{message("page.registration.card.title")}</p>
        </NavLink>
      </li>
    </ul>
  </>
}