import {SERVICE} from "../service";
import {redirect} from "react-router-dom";

export async function userLoader() {
  try {
    const user = await SERVICE.getUser();
    if (!user || user.status !== 200) {
      if (user && user.status === 401) {
        window.sessionStorage.setItem("redirectOriginalLocation", window.location.href)
        return redirect("/login");
      }
      console.error("User not loaded:", user)
      return null;
    }

    if (window.sessionStorage.getItem("redirectOriginalLocation")) {
      const origin = window.sessionStorage.getItem("redirectOriginalLocation");
      window.sessionStorage.setItem("redirectOriginalLocation", "")
      window.location.href = origin
    }

    return {...user.data}

  } catch (e) {
    console.error(e);
    return null;
  }

}