import {SERVICE} from "../service";
import {redirect} from "react-router-dom";

export async function scenariosLoader() {
  try {
    const scenarios = await SERVICE.getEditableScenarios();
    if (scenarios && scenarios.status === 200) return scenarios.data;

    console.error("Scenarios not loaded:", scenarios)
  } catch (e) {
    console.error(e);
  }
  return redirect("/");
}