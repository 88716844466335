import message from "../../message";
import React from "react";
import Card from "../common/Card";
import {SectionInput} from "./SectionInput";

export function SectionsCard({register, sections, add, remove}) {
  return (
      <>
          <Card title={<SectionCardTitle/>}>
            {
              sections
                  ? sections.map(
                      (section, idx) =>
                          <SectionInput key={idx}
                                        index={idx}
                                        register={register}
                                        section={section}
                                        remove={remove}
                          />
                  )
                  : <></>
            }
              <button className="btn btn-outline-success shadow-sm ms-2 col-1" type="button" onClick={() => add()}>
                  <i className="bi bi-plus-circle"></i>
              </button>
        </Card>
      </>
  );
}

function SectionCardTitle() {
    return (
        <>
            <div className="card-title mb-0">
                <h5 className="align-items-center text-center">
                    {message("page.editor.card.form.sections.title")}
                </h5>
            </div>
        </>
    );
}
