import React, {useEffect, useState} from 'react';
import {Link, Outlet, useLoaderData, useLocation, useNavigation, useRevalidator} from "react-router-dom";
import message from "../message";
import {SERVICE} from "../service";
import packageJson from '../../package.json';
import ModalWindow from "../components/ModalWindow";

export default function MainLayout() {
  const user = useLoaderData();
  return <Layout user={user}/>
}

export function Layout({user}) {
  const navigation = useNavigation(); //navigation.state !== "loading"
  const revalidator = useRevalidator();
  let location = useLocation();

  const spinner = useState(false)
  const [eventSource, updateEventSource] = useState(undefined);
  const [modal, updModal] = useState(null);

  useEffect(() => {
    if (eventSource) {
      console.debug("Close Event Source")
      eventSource.close();
      console.debug(eventSource)
      updateEventSource(null)
    }
  }, [location])

  useEffect(() => {
    if (!eventSource) {
      const path = location.pathname.split("/")

      if (isPageWithoutNotification(path)) return

      console.debug("Opening Event Source")

      connectEventSource(path, updateEventSource);
    }
  }, [eventSource, location])

  return <>
    <div style={{display: "flex", flexDirection: "column", height: "lvh", minWidth: "1024px"}}>
      <header className="d-flex flex-wrap justify-content-center border-bottom bg-body-tertiary shadow flex-shrink-0">
        <div className="d-flex align-items-center py-0 my-0 me-md-auto">
          <Link to="/" className="text-decoration-none link-body-emphasis">
            <h1 className="px-3 py-0 my-0">{message('nav.title')}</h1>
          </Link>
          <h5 className="pb-3 py-0 my-0 fw-light">{user ? user.username : ""}</h5>
        </div>

        {/*<p className="px-5"><i>{message('ru.nav.text.disclaimer')}</i></p>*/}

        {
          user ? <div className="justify-content-center py-3">
            <ul className="nav nav-pills mx-3">
              <li className="nav-item ">
                <Link to="/" className="nav-link py-0 mx-1 text-secondary">
                  {message('nav.link.newgame')}
                </Link>
              </li>
              <li className="nav-item ">
                <Link to="/editor" className="nav-link py-0 mx-1 text-secondary">
                  {message('nav.link.add.scenario')}
                </Link>
              </li>

              <li className="nav-item ">
                <button className="nav-link py-0 mx-1 text-secondary"
                        onClick={() => SERVICE.doLogout().then(() => revalidator.revalidate())}
                >
                  {message('nav.link.logout')}
                </button>
              </li>

            </ul>
          </div> : <></>
        }
      </header>

      {
        navigation.state !== "idle" || revalidator.state !== "idle" || spinner[0] ?
          <div className="fixed-top row justify-content-center">
            <div className="d-flex spinner-border mx-3 my-3" role="status">
            </div>
          </div> : <></>
      }

      {
        modal ?
          <ModalWindow title={modal.title} closeModal={() => updModal(null)}>
            {modal.content}
          </ModalWindow>
          : <></>
      }

      <main className="mx-auto" style={{flexGrow: 1, maxWidth: "1620px"}}>
        <Outlet context={{user, spinner, eventSource, modal, updModal}}/>
      </main>

      <footer className="footer d-inline-flex bg-secondary text-end flex-shrink-0">
        <div className="mx-3">
          Ilya Zhukau - Detective Engine {packageJson.version}
        </div>
      </footer>

    </div>
  </>
}

function createEventSource(token, path) {
  return new EventSource("/api/notifications?token=" + token + "&entity=" + path[1] + "&id=" + path[2])
}

function configureEventSource(eventSource, updateEventSource) {
  console.debug("Event Source got", eventSource)

  eventSource.onopen = (event) => {
    console.debug("Event Source Ready", event)
  }

  eventSource.onclose = (event) => {
    console.debug("Event Source Close", event)
  }

  eventSource.onerror = (event) => {
    console.error("Event Source closed with error", event)
    eventSource.close();

    const path = location.pathname.split("/")
    connectEventSource(path, updateEventSource);
  }

  return eventSource
}

function connectEventSource(path, updateEventSource) {
  SERVICE.getNotificationToken()
    .then(response => {
      if (response && response.status === 200) {
        const token = response.data;
        return createEventSource(token, path)
      }
      throw new Error(message("notification.token.invalid"))
    })
    .then(es => configureEventSource(es, updateEventSource))
    .then(es => updateEventSource(es))
    .catch(err => console.error(err))
}


function isPageWithoutNotification(path) {
  return !path[1] && !path[2] || (path[1] === "login" || path[1] === "registration" || path[1] === "editor" || path[1] === "");
}
