import React, {useEffect, useState} from 'react';
import {useLoaderData, useNavigate, useOutletContext, useRevalidator} from "react-router-dom";
import message from "../message";
import {SERVICE} from "../service";
import Container from "../components/common/Contatiner";
import Card from "../components/common/Card";
import Notifications from "../components/notifications/Notifications";
import notify from "../notify";
import {useForm} from "react-hook-form";

export default function LobbyContainer() {
  let revalidator = useRevalidator()
  let navigate = useNavigate()



  let spinner = useOutletContext().spinner[1]
  let eventSource = useOutletContext().eventSource;


  const lobby = useLoaderData()
  const players = useLoaderData()?.players
  const user = useOutletContext().user

  const [name, updateName] = useState(lobby.scenarioName)
  const [master, updateMaster] = useState(false)
  const [notifications, updateNotifications] = useState([])


  const {register, getValues} = useForm({defaultValues: {free: lobby?.free || false}})

  async function block(username) {
    spinner({})
    const res = await SERVICE.doBlockLobbyPlayer(lobby.id, username)
    if (res && res.status === 200) {
      // revalidator.revalidate()
    }
    if (res && res.status === 403) {
      notify(updateNotifications, "error", message("notification.lobby.action.unavailable"))
    }
    spinner(null)
  }

  async function approve(username) {
    spinner({})
    const res = await SERVICE.doApproveLobbyPlayer(lobby.id, username)
    if (res && res.status === 200) {
      // revalidator.revalidate()
    }
    if (res && res.status === 403) {
      notify(updateNotifications, "error", message("notification.lobby.action.unavailable"))
    }
    spinner(null)
  }

  async function start() {
    console.debug("Start game")
    spinner({})
    const data = {id: lobby.id, scenarioId: lobby.scenarioId, free: JSON.stringify(getValues("free"))}

    const res = await SERVICE.createGame(JSON.stringify(data))
    if (res && res.status === 200) {
      console.debug("Game run:" + res.data.id)
      navigate("/game/" + res.data.id)
    }
    if (res && res.status === 403) {
      notify(updateNotifications, "error", message("notification.lobby.action.unavailable.start.game"))
    }
    spinner(null)
  }

  useEffect(() => {
    if ((players) && user) {
      updateMaster(players.filter(player => player.username === user.username)[0].master)
    }
  }, [players])

  useEffect(() => {
    if (eventSource && !eventSource.onmessage) {
      console.debug("Init Event Source")

      eventSource.onmessage = (event) => {
        console.debug("New message:", event.data);

        const data = JSON.parse(event.data);

        if (data.code === "notification.lobby.game.started") {
          console.debug("Game started")
          notify(updateNotifications, "info", message("notification.lobby.action.redirecting"))
          revalidator.revalidate()
        }

        if (data.code === "notification.lobby.player.connected") {
          console.debug("New player")
          notify(updateNotifications, "info", message("notification.lobby.event.player.new", null, [data.attachments.username]))
          revalidator.revalidate()
        }

        if (data.code === "notification.lobby.player.blocked") {
          console.debug("Player blocked")
          notify(updateNotifications, "info", message("notification.lobby.event.player.blocked", null, [data.attachments.username]))
          revalidator.revalidate()
        }

        if (data.code === "notification.lobby.player.approved") {
          console.debug("Player approved")
          notify(updateNotifications, "info", message("notification.lobby.event.player.approved", null, [data.attachments.username]))
          revalidator.revalidate()
        }
      }
      console.debug("Event Source Object Configured")
    }
    return () => {
    }
  }, [eventSource])

  return <>
    <Container>
      <div className="col">
        <Card className="pb-3 px-5"
              title={
                <>
                  <div className="row mx-1 my-0 py-0">

                    <div className="d-inline-flex">
                      <span className="input-group-text mx-1 border-0">
                        <h5 className="m-0 p-0">{message("page.lobby.card.form.title")}</h5>
                      </span>

                      <input type="text" className="form-control"
                             value={name}
                             onChange={event => updateName(event.target.value)}
                             disabled
                      />
                    </div>

                    {
                      master &&
                      <>
                        <div className="d-inline-flex">
                          <span className="input-group-text ms-1 border-0" title="Buhf ,tp vfcnhtf">
                            <p className="m-0 p-0">{message("page.lobby.card.form.game.type.label")}</p>
                          </span>
                          <input className="form-check-input me-1 my-auto"
                                 type="checkbox" name="free"
                                 {...register("free")}
                          />
                          <i className="bi bi-question"
                             title={message("page.lobby.card.form.game.type.help.tooltip")}
                          ></i>
                        </div>
                      </>
                    }

                  </div>
                </>
              }
        >

          {
            players && players.length > 0 ?
              players.map(player =>
                <LobbyPlayer
                  key={player.username}
                  player={player}
                  block={block}
                  approve={approve}
                />
              )
              : <></>
          }

          <div className="text-end">
            <button className="btn btn-outline-primary shadow-sm"
                    onClick={start}
            >
              {message("page.lobby.card.form.button.start")}
            </button>
          </div>

        </Card>
      </div>
      <Notifications notifications={notifications}/>
    </Container>
  </>
}

function LobbyPlayer({player, block, approve}) {

  let color = getStatusColor(player.blocked ? "BANED" : player.approved ? "CONNECTED" : "NEW")

  return <>
    <div className="row my-3">
      <div className="input-group">
                <span className="input-group-text bg-light border-0 rounded-end-2 fw-bold col-3 mx-1"
                      data-toggle="tooltip"
                      title={message("page.lobby.tooltip.form.role")}>
                   {getRoleLabel(player.master)}
                </span>
        <input className={"bg-gradient border-0 rounded-2 form-control col" + color}
               readOnly={true}
               value={player.username}
               data-toggle="tooltip"
               title={message("page.lobby.tooltip.form.status.new")}
               type="text"
        />

        <button className="btn btn-outline-secondary border-0 bg-gradient rounded-2 mx-1" data-toggle="tooltip"
                title={message("page.lobby.tooltip.form.button.approve")}
                onClick={() => approve(player.username)}>
          <i className="bi bi-check-circle-fill"></i>
        </button>

        <button className="btn btn-outline-dark border-0 bg-gradient rounded-2 mx-1" data-toggle="tooltip"
                title={message("page.lobby.tooltip.form.button.block")}
                onClick={() => block(player.username)}>
          <i className="bi bi-person-x-fill"></i>
        </button>
      </div>
    </div>
  </>
}

function getRoleLabel(master) {
  return master
    ? message("page.lobby.card.form.role.master")
    : message("page.lobby.card.form.role.player")
}

function getStatusColor(status) {
  switch (status) {
    case "NEW":
      return " text-muted";
    case "CONNECTED":
      return " text-dark fw-bold";
    case "BANED":
      return " text-dark fw-bold text-decoration-line-through";
    default:
      return " fw-bold";
  }
}
