export default function Card({title, className, children, style}) {

  return <>
    <div style={style} className={"card shadow mx-1 my-3 py-1 pb-3 bg-body-tertiary " + className}>
      {
        title ?
            <div className="card-title mt-2 mb-0 mx-3">
              {title}
            </div>
            : <></>
      }
      <div className="card-body my-0 mx-1 py-1 px-1">
        {children}
      </div>
    </div>
  </>
}