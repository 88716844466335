import React from "react";
import message from "../../message";

export function SectionInput({index, register, section, remove}) {

  const text = {
    sections: {
      name: message("page.editor.card.form.sections.field.name"),
      key: message("page.editor.card.form.sections.field.key"),
      order: message("page.editor.card.form.sections.field.order"),
    },
  }

  return (
      <>
        <div className="card shadow-sm w-100 my-3 section">
          <div className="card-body">
            <div>
              <div className="input-group my-1">
                <span className="input-group-text сol-auto">{text.sections.name}</span>
                <input {...register("sections." + index + ".name", {
                  required: true
                })}
                       className="form-control" name={"sections." + index + ".name"} type="text"
                       defaultValue={section.name} required={true}
                />

                <button className="btn btn-outline-danger" type="button" onClick={() => remove(section.key)}>
                  <i className="bi bi-dash-circle"></i>
                </button>
              </div>

              <div className="input-group my-1">
                <span className="input-group-text сol-auto">{text.sections.key}</span>
                <input {...register("sections." + index + ".key", {
                  required: true
                })}
                       className="form-control сol-auto" name={"sections." + index + ".key"} type="text"
                       defaultValue={section.key} required={true}
                />

                <span className="input-group-text сol-auto">{text.sections.order}</span>
                <input {...register("sections." + index + ".order", {
                  required: false
                })}
                       className="form-control сol-auto" name={"sections." + index + ".order"} type="number"
                       defaultValue={section.order}
                />
              </div>
            </div>
          </div>
        </div>
      </>
  )
}