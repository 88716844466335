import React, {useEffect} from "react";
import {SERVICE} from "../../service";
import {useForm} from "react-hook-form";
import {useOutletContext} from "react-router-dom";
import message from "../../message";

export default function ResourceInput({updateScenario}) {
  const spinner = useOutletContext().spinner[1]
  const {register, handleSubmit, resetField, watch} = useForm()

  async function onSubmit(data) {
    spinner({})
    if (!data.image) return
    const formData = new FormData()
    formData.append("image", data.image[0])
    formData.append("originalFilename", data.image[0]?.name)
    await SERVICE.createResource(formData);
    await SERVICE.getAvailableResources()
      .then(response => updateList(response))
      .then(() => resetField("image"))
    spinner(null)
  }

  async function updateList(response) {
    if (response && response.status === 200) {
      updateScenario(scenario => ({...scenario, resources: response.data}))
    }
  }

  useEffect(() => {
    const subscription = watch((value, {name, type}) => {
        if (name === "image" && type === "change") {
          onSubmit(value)
        }
      }
    )
    return () => subscription.unsubscribe()
  }, [watch])

  return (
    <>
      <div className="card shadow-sm w-100 my-3">
        <div className="card-body">
          <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
            <div className="input-group my-1">
              <input {...register("image")} type="file" name="image" className="form-control"/>
            </div>
            <p className="text-end fw-lighter fst-italic mx-3 mb-0">{message("page.editor.card.form.resources.auto-upload")}</p>
          </form>
        </div>
      </div>
    </>
  )
}