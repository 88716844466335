import Card from "../common/Card";
import React from "react";
import {SERVICE} from "../../service";
import message from "../../message";
import {useOutletContext} from "react-router-dom";
import {useForm} from "react-hook-form";
import notify from "../../notify";

export default function GamePanelControl({game, master, sections, tab, switchTab, updNotifications}) {
  const {register, resetField, handleSubmit} = useForm({defaultValues: {key: ""}})
  const spinner = useOutletContext().spinner[1]

  async function onSubmit(data) {
    spinner({})
    const res = await SERVICE.addElement(game.id, data)
    if (res && res.status === 200) resetField("key")
    if (res && res.status === 400) {
      if (res.data === "Game closed") {
        notify(updNotifications, "error", message("notification.game.closed"))
      }
      resetField("key")
    }
    if (res && res.status === 403) {
      notify(updNotifications, "error", message("notification.game.action.forbidden"))
      resetField("key")
    }
    spinner(null)
  }

  return <>
    <Card className="sticky-top pb-0">
      {
        master || game.type === "free" ?
            <div className="card-body pt-2">
              <form className="needs-validation input-group shadow rounded-2" onSubmit={handleSubmit(onSubmit)}>
                <input className="form-control border-1 border-dark"
                       placeholder={message("page.game.card.control.form.placeholder")}
                       type="text" {...register("key")} autoComplete="off"
                />
                <button className="col-2 border-1 border-dark btn btn-secondary" type="submit">
                  {message("page.game.card.control.form.button")}
                </button>
              </form>
            </div>
            : <></>
      }

      <ul className="nav nav-tabs border-0 nav-justified my-0 border-secondary">
        {
          sections && sections.length > 0
              ? sections.map((section) =>
                  <li key={section.key} className="nav-item my-0 mx-1"
                      onClick={() => switchTab(section.key)}>
                    <button className=
                                {
                                  section.key === tab
                                      ? "game-tab-active text-nowrap nav-link border-bottom-0 rounded rounded-bottom-0 border-secondary fw-bold bg-light active"
                                      : "game-tab text-nowrap nav-link border-bottom-0 rounded rounded-bottom-0 border-secondary fw-medium text-muted"
                                }
                    >
                      {section.name}
                    </button>
                  </li>
              )
              : <></>
        }
      </ul>
      {/*<hr className="my-0 bg-body-secondary"/>*/}
    </Card>
  </>
}