import React from "react";

export default function ModalWindow({title, closeModal, children}) {
  return <>
    <div style={{width: "100lvw", height: "100lvh"}}
         className="modal mx-auto fade z-5  d-flex show"
         role="dialog">
      <div className="modal-dialog modal-dialog-scrollable modal-fullscreen">

        <div style={{overflowY: "scroll"}} className="modal-content">

          <div className="modal-header d-inline-flex justify-content-between">

            <h1 className="modal-title fs-5 d-flex">{title}</h1>

            <button className="btn-close mx-3 d-flex"
                    type="button" aria-label="Close"
                    onClick={closeModal}
            >
            </button>
          </div>

          <div className="modal-body text-center">
            {children}
          </div>

        </div>
      </div>
    </div>
  </>
}