import React from "react";

export default function Notification({text, type}) {
  let color = type === "error" ? "border-danger" : "border-black"
  return (
      <>
        <div className={"toast my-1 show border-1 " + color}>
          <div className="toast-body fw-bold text-center">
            {text}
          </div>
        </div>
      </>
  );
}