import message from "../message";
import React from "react";

export default function FormInput({
                                    register, name, type = "text", mistake, placeholder,
                                    onChange, autoComplete, required = false
                                  }) {
  return (
      <>
        <input {...register(name, { required: required })} onChange={onChange}
               className={"form-control shadow-sm " + (mistake ? "is-invalid" : "")}
               id={name} name={name} type={type}
               autoComplete={autoComplete} placeholder={placeholder}
               required={required}
        />
        {
          mistake ? <p className="invalid-feedback">{message(mistake)}</p> : <p></p>
        }
      </>
  );
}