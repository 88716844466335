import {SERVICE} from "../service";
import {redirect} from "react-router-dom";

export async function startLoader() {
  try {

    const user = await SERVICE.getUser()
    if (!user || user.status !== 200) {
      return redirect("/login");
    }

    const scenarios = await SERVICE.getAvailableScenarios()
    const lobbies = await SERVICE.getAvailableLobbies()
    const games = await SERVICE.getAvailableGames()

    if ((scenarios && lobbies && games)
        && (scenarios.status === 200 && lobbies.status === 200 && games.status === 200)
    )
      return {
        scenarios: scenarios.data,
        lobbies: lobbies.data,
        games: games.data,
      };

    console.error("Scenarios not loaded:", scenarios, lobbies, games)
  } catch (e) {
    console.error(e);
  }
  return redirect("/login");
}