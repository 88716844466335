import GameSidebarTitle from "./GameSidebarTitle";
import GameSidebarPlayers from "./GameSidebarPlayers";
import GameSidebarNotesInput from "./GameSidebarNotesInput";
import GameSidebarNotes from "./GameSidebarNotes";
import React from "react";

export default function GameSidebar(props) {
  return (
    <>
      <nav className="col-3 col-xxl-3">
        <div style={{display: "flex", flexDirection: "column", flexShrink: 0, flexGrow: 1}}>
          <GameSidebarTitle game={props.game}/>
        </div>
        <div style={{display: "flex", flexDirection: "column", flexShrink: 0, flexGrow: 1}}>
          <GameSidebarPlayers players={props.players}/>
        </div>
        <div className="sticky-top" style={{display: "flex", flexDirection: "column", flexShrink: 0, flexGrow: 1}}>
          <GameSidebarNotesInput gameId={props.game.id} updNotifications={props.updNotifications}/>
        </div>
        <div style={{display: "flex", flexDirection: "column", flexShrink: 0, flexGrow: 1}}>
          <GameSidebarNotes notes={props.notes} updNotifications={props.updNotifications}/>
        </div>
      </nav>
    </>
  );
}