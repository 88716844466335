const DEFAULT_LANG = "ru";

const dictionary = {
  "en": {
    "nav.title": "GIVE A CLUE",
    "nav.link.newgame": "New Game",
    "nav.link.add.scenario": "Editor",
    "nav.text.disclaimer": "This is a beta version, there may be bugs. Sorry",
    "nav.link.logout": "Logout",
    "page.login.card.title": "Login",
    "page.login.card.form.label.username": "Username",
    "page.login.card.form.label.password": "Password",
    "page.login.card.form.label.submit": "Login",
    "page.registration.card.title": "Registration",
    "page.registration.card.form.label.username": "Username",
    "page.registration.card.form.label.password": "Password",
    "page.registration.card.form.label.submit": "Submit",
    "page.start.card.tittle": "Available scenarios",
    "page.card.form.label.submit": "Create lobby",
    "page.card.form.text.scenario.empty": null,
    "page.editor.card.form.title": null,
  },
  "ru": {
    "nav.title": "GIVE A CLUE",
    "nav.link.newgame": "Новая игра",
    "nav.link.add.scenario": "Редактор",
    "nav.text.disclaimer": "Это бета версия, возможны неполадки. Извините",
    "nav.link.logout": "Выход",

    "page.login.card.title": "Вход",
    "page.login.card.form.username.label": "Никнейм",
    "page.login.card.form.username.placeholder": "Никнейм",
    "page.login.card.form.password.label": "Пароль",
    "page.login.card.form.password.placeholder": "Пароль",
    "page.login.card.form.submit": "Войти",

    "page.registration.card.title": "Регистрация",
    "page.registration.card.form.username.label": "Никнейм",
    "page.registration.card.form.username.placeholder": "Никнейм",
    "page.registration.card.form.password.label": "Пароль",
    "page.registration.card.form.password.placeholder": "Пароль",
    "page.registration.card.form.submit": "Подвердить",

    "page.start.card.lobbies.tittle": "Текущие лобби",
    "page.start.card.lobbies.subtitle": "Выберете лобби чтобы вернутся в него",
    "page.start.card.games.tittle": "Начатые игры",
    "page.start.card.games.subtitle": "Выберете игру чтобы вернутся в неё",
    "page.start.card.scenarios.tittle": "Доступные сценарии",
    "page.start.card.scenarios.subtitle": "Выберете сценари чтобы начать новую игру",
    "page.start.card.form.text.scenarios.empty": "У вас нет доступных сценариев.",

    "page.card.form.text.scenarios.empty": "У вас нет доступных сценариев.",

    "page.editor.card.form.title": "Название сценария:",
    "page.editor.card.form.resources.title": "Ресурсы",
    "page.editor.card.form.resources.field.name": "Имя файла",
    "page.editor.card.form.resources.tooltip.preview": "Открыть файл в новом окне",
    "page.editor.card.form.resources.auto-upload": "Загрузка начнется автоматически после выбора файла",

    "page.editor.card.form.sections.title": "Секции",
    "page.editor.card.form.sections.field.name": "Название",
    "page.editor.card.form.sections.field.key": "Ключ",
    "page.editor.card.form.sections.field.order": "Порядок",

    "page.editor.card.form.elements.title": "Элементы",
    "page.editor.card.form.elements.field.name": "Название",
    "page.editor.card.form.elements.field.key": "Ключ",
    "page.editor.card.form.elements.field.title": "Заголовок",
    "page.editor.card.form.elements.field.section": "Секция",
    "page.editor.card.form.elements.field.resource": "Ресурс",
    "page.editor.card.form.elements.field.order": "Порядок",
    "page.editor.card.form.elements.field.text": "Текст",
    "page.editor.card.form.elements.field.access": "Доступ",
    "page.editor.card.form.elements.field.access.always": "Всегда",
    "page.editor.card.form.elements.field.access.request": "По запросу",

    "page.scenarios.card.form.name.label":"Создать новый сценарий",
    "page.scenarios.card.form.name.placeholder":"Название сценария",
    "page.scenarios.card.form.submit":"Создать",

    "page.lobby.card.form.title": "Игра:",
    "page.lobby.card.form.role.master": "Мастер",
    "page.lobby.card.form.role.player": "Игрок",
    "page.lobby.card.form.button.start": "Начать игру",
    "page.lobby.tooltip.form.role": "Роль игрока в игре",
    "page.lobby.tooltip.form.status.new": "Статус игрока: Новый",
    "page.lobby.tooltip.form.status.blocked": "Статус игрока: Заблокирован",
    "page.lobby.tooltip.form.status.approved": "Статус игрока: Принят",
    "page.lobby.card.form.game.type.label": "Свободная игра",
    "page.lobby.card.form.game.type.help.tooltip": "Игра без мастера-игрока. Любой игрок может проверить элемент игры.",

    "page.game.card.scenario.title.close": "Игра окончена",
    "page.game.card.players.title": "Игроки",
    "page.game.card.notes.form.placeholder": "Напиши заметку и отправь Ctrl + Enter",
    "page.game.card.notes.title": "Заметки",
    "page.game.card.control.form.placeholder": "Какой ключ у подсказки?",
    "page.game.card.control.form.button": "Проверить",

    "notification.lobby.action.unavailable": "Действие не возможно",
    "notification.lobby.action.redirecting": "Игра началась. Пренаправление...",
    "notification.lobby.event.player.approved": "'${args[0]}' подвержден",
    "notification.lobby.event.player.blocked": "'${args[0]}' заблокирован",
    "notification.lobby.event.player.new": "'${args[0]}' присоединился к лобби",

    "notification.game.element.exist": "Игровой элемент уже добавлен",
    "notification.game.element.notfound": "Игровой элемент не доступен",
    "notification.game.element.add": "'${args[1]}' добавил ировой элемент '${args[0]}' в '${args[2]}'",
    "notification.game.note.added": "'${args[0]}' добавил заметку",
    "notification.game.note.updated": "'${args[0]}' отредактировал заметку",
    "notification.game.note.removed": "'${args[0]}' удалил заметку",
    "notification.game.update": "Страница будет перезагружена! Состояние игры принудительно изменено",
    "notification.game.closed": "Игра окончена. Действие не возможно.",
    "notification.game.action.forbidden": "Действие не возможно",
    "notification.unknown.error": "Что-то пошло не так как планировалось",

    "notification.validation.error": "Неправильные данные",
    "notification.unauthorized.error": "Неправильные никнейм или пароль",
    "notification.game.note.remove.forbidden": "Удалить заметку может только автор или создатель игры",
    "error.username.exist": "Никнейм уже зарегистрирован",

    "validation.user.username.size": "Минимальная длинна 5 символов, максимальная 15",
    "validation.user.password.size": "Минимальная длинна 7 символов, максимальная 32",

  }
};

export default function message(key, lang, args) {
  let messages = dictionary[lang]
  if (!messages) {
    messages = dictionary[DEFAULT_LANG]
  }
  let message = messages[key]
  if (!message) {
    message = key
  }
  return eval("`" + message + "`")
}