// noinspection DuplicatedCode

import React, {useState} from "react";
import {useLoaderData, useNavigate} from "react-router-dom";
import Container from "../components/common/Contatiner";
import Card from "../components/common/Card";
import message from "../message";
import FormInput from "../components/FormInput";
import {SERVICE} from "../service";
import {useForm} from "react-hook-form";
import ListCardItem from "../components/ListCardItem";

export default function ScenariosContainer() {
  const scenarios = useLoaderData()
  const navigate = useNavigate()

  const {register, handleSubmit, formState: {errors}, formState} = useForm()
  const [mistakes, setMistakes] = useState(null)

  async function onSubmit(data, e) {
    const response = await SERVICE.createScenario(data)
    if (response && response.status === 200) {
      return navigate("/editor/" + response.data.id)
    }
    if (response && response.status === 400) {
      setMistakes(response.data)
    }
  }

  return <>
    <Container>
      <div style={{width: "50lvw", maxWidth: "810px"}}>

        <Card
          title={
            <h3 className="text-center">{message("page.scenarios.card.form.name.label")}</h3>
          }
        >
          <form className="needs-validation px-5" onSubmit={handleSubmit(onSubmit)}>

            <FormInput register={register} name={"name"}
                       mistake={mistakes?.name} placeholder={message("page.scenarios.card.form.name.placeholder")}
                       onChange={event => event.target.className = "form-control shadow-sm"}
                       autoComplete="off"/>

            <button className="btn btn-primary shadow"
                    type="submit">{message("page.scenarios.card.form.submit")}</button>
          </form>
        </Card>

        <Card>
          <ul className="list-group px-5">
            {
              scenarios.map(scenario =>
                <ListCardItem key={scenario.id} onClick={() => navigate("/editor/" + scenario.id)}
                              text={scenario.name}/>
              )
            }
          </ul>
        </Card>

      </div>
    </Container>
  </>
}