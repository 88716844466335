import Card from "./common/Card";
import message from "../message";

export default function StartCard({items, itemMapper, type}) {
  return (
      <>
        <Card className="pb-3"
              title={
                <>
                  <h3 className="text-center">{message("page.start.card."+type+".tittle")}</h3>
                  <p className="text-center fst-italic">{message("page.start.card."+type+".subtitle")}</p>
                </>
              }
        >
          <ul className="list-group px-5">
            {
              items.map(itemMapper)
            }
          </ul>
        </Card>
      </>
  );
}