import React from "react";
import Notification from "./Notification";

export default function Notifications({notifications}) {
  return (
      <div className="toast-container position-fixed z-3 bottom-0 end-0 px-5 pb-3 pt-0">
        {
          notifications && notifications.length > 0
              ? notifications.map(
                  (notification, i) => <Notification key={i} text={notification.text} type={notification.type}/>
              )
              : <></>
        }
      </div>
  );
}