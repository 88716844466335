import message from "../../message";
import React from "react";
import Card from "../common/Card";
import {ElementInput} from "./ElementInput";

export function ElementsCard({register, elements, sections, resources, add, remove}) {

  const sectionList = sections.map(section => {
    return {key: section.key, name: section.name}
  })

  const resourceList = resources.map(resource => {
    return {id: resource.id, name: resource.name}
  })

  return (
      <>
          <Card title={<ElementCardTitle/>}>
            {
                elements
                    ? elements.map(
                        (element, idx) =>
                            <ElementInput key={idx}
                                          index={idx}
                                          register={register}
                                          element={element}
                                          remove={remove}
                                          sectionList={sectionList}
                                          resourceList={resourceList}
                            />
                    )
                    : <></>
            }
              <button className="btn btn-outline-success shadow-sm ms-2 col-1" type="button" onClick={() => add()}>
              <i className="bi bi-plus-circle"></i>
            </button>
        </Card>
      </>
  );
}

function ElementCardTitle() {
    return (
        <>
            <div className="card-title mb-0">
                <h5 className="align-items-center text-center">
                    {message("page.editor.card.form.elements.title")}
                </h5>
            </div>
        </>
    );
}
