import {SERVICE} from "../service";
import {redirect} from "react-router-dom";

export async function scenarioLoader({params}) {
  try {
    const scenario = await SERVICE.getScenario(params.scenarioId)
    const resources = await SERVICE.getAvailableResources();

    if ((scenario && resources) && (scenario.status === 200 && resources.status === 200))
      return {
        ...scenario.data,
        resources: resources.data
      };

    console.error("Scenarios not loaded:", scenario)
  } catch (e) {
    console.error(e);
  }
  return redirect("/editor");
}