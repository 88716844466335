import React, {useEffect, useState} from "react";
import {useLoaderData, useOutletContext} from "react-router-dom";
import {EditorTitle} from "../components/editor/EditorTitle";
import {ElementsCard} from "../components/editor/ElementsCard";
import {SectionsCard} from "../components/editor/SectionsCard";
import {ResourcesCard} from "../components/editor/ResourcesCard";
import Container from "../components/common/Contatiner";
import Card from "../components/common/Card";
import {useForm} from "react-hook-form";
import {random} from "lodash";
import {SERVICE} from "../service";

export default function EditorContainer() {
  const spinner = useOutletContext().spinner[1]
  const [scenario, updateScenario] = useState(useLoaderData())
  const {register, handleSubmit, setValue, watch} =
    useForm({
      defaultValues: {
        id: scenario.id,
        name: scenario.name,
        sections: scenario.sections,
        elements: scenario.elements,
        shared: scenario.shared,
      }
    })

  async function onSubmit(data) {
    if (!data) return
    console.debug(data)
    spinner({})
    await SERVICE.saveScenario(scenario.id, data);
    spinner(null)
  }

  async function addSection() {
    setValue("sections", [...scenario.sections, {key: random(1, 100).valueOf(), name: ""}])
  }

  async function removeSection(key) {
    setValue("sections", scenario.sections.filter(section => section.key !== key))
  }

  async function addElement() {
    setValue("elements", [...scenario.elements, {key: random(1, 100).valueOf(), name: ""}])
  }

  async function removeElement(key) {
    setValue("elements", scenario.elements.filter(element => element.key !== key))
  }

  useEffect(() => {
    const subscription = watch((value) => {
      updateScenario(prev => ({resources: prev.resources, ...value}))
    })
    return () => subscription.unsubscribe()
  }, [watch])

  return <>
    <Container>
      <div style={{width: "50lvw", maxWidth: "810px"}}>

        <ResourcesCard resources={scenario.resources} updateScenario={updateScenario}/>

        <Card>
          <form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>

            <EditorTitle register={register} title={scenario.name} shared={scenario.shared}/>

            <SectionsCard register={register} sections={scenario.sections}
                          add={addSection} remove={removeSection}/>

            <ElementsCard register={register} elements={scenario.elements}
                          sections={scenario.sections} resources={scenario.resources}
                          add={addElement} remove={removeElement}/>
            <div className="d-flex  justify-content-end sticky-bottom start-100">
              <button className="btn btn-lg btn-success rounded-5 my-3" type="submit">
                <i className="bi bi-cloud-arrow-up"></i>
              </button>
            </div>
          </form>
        </Card>
      </div>
    </Container>
  </>
}


