import Card from "../common/Card";
import message from "../../message";
import React from "react";

export default function GamePanelElements({elements, updModal}) {

  function openModal(resourceId, title) {
    const modal = {
      resourceId: resourceId,
      title: title,
      content: <img className="mx-auto my-auto"
                    src={"/api/resources/" + resourceId}
                    alt={title}
      />
    }
    updModal(modal)
  }


  return <>
    <Card className={"min-vh-100"}>
      <div className="card-body">
        {
          elements && elements.length > 0 ?
            elements.map(element =>
              <div key={element.key} className="card shadow-sm my-1">
                <div className="card-title text-center mt-3 mb-0">
                  <h5>{element.title}</h5>
                </div>
                <div
                  className="card-body justify-content-center m-1">
                  <p className="m-1" dangerouslySetInnerHTML={{__html: element.text}}></p>
                  {
                    element.resourceId
                      ?
                      <>
                        <img className="img-thumbnail rounded mx-auto d-block"
                             src={"/api/resources/" + element.resourceId}
                             alt={message("todo.alt")}
                             onClick={() => openModal(element.resourceId, element.title)}
                        />
                      </>
                      :
                      <></>
                  }
                </div>
              </div>
            )
            : <></>
        }
      </div>
    </Card>
  </>
}