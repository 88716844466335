import {redirect} from "react-router-dom";
import {SERVICE} from "../service";

export async function lobbyLoader({params}) {
  try {
    const connected = await SERVICE.doConnectLobby(params.lobbyId)
    const lobby = await SERVICE.getLobby(params.lobbyId)

    if ((connected && lobby) && (connected.status === 200 && connected.status === 200)) {

      if (lobby.data.gameId) {
        console.debug("Game started, redirect to game:", lobby)
        return redirect("/game/" + lobby.data.gameId)
      }

      return lobby.data;
    }
  } catch (e) {
    console.error(e);
  }
  return redirect("/");
}