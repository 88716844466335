import React from "react";
import {Link, useOutletContext} from "react-router-dom";
import message from "../../message";
import {SERVICE} from "../../service";

export default function ResourcePreview({id, name, updateScenario}) {
    const spinner = useOutletContext().spinner[1]

    async function remove(id) {
        spinner({})
        await SERVICE.removeResource(id);
        await SERVICE.getAvailableResources().then(response => updateList(response))
        spinner(null)
    }

    async function updateList(response) {
        if (response && response.status === 200) {
            updateScenario(scenario => ({...scenario, resources: response.data}))
        }
    }

  return (
      <>
          <div className="input-group mb-1">
              <span className="input-group-text col-auto">{message("page.editor.card.form.resources.field.name")}</span>
          <Link reloadDocument={true}
                to={window.location.protocol + "//" + window.location.host + "/api/resources/" + id}
                title={message("page.editor.card.form.resources.tooltip.preview")}
                className="btn btn-outline-secondary"
                target="_blank"
          >
            <i className="bi bi-easel"></i>
          </Link>

              <input type="text" className="form-control" value={name} disabled/>

              <button type="submit" className="btn btn-outline-danger сol-auto" onClick={() => remove(id)}>
                  <i className="bi bi-dash-circle"></i>
              </button>
          </div>
      </>
  )
}