import TextareaAutosize from "react-textarea-autosize";
import React from "react";
import {SERVICE} from "../../../service";
import message from "../../../message";
import {useOutletContext} from "react-router-dom";
import {useForm} from "react-hook-form";
import notify from "../../../notify";

export default function GameSidebarNotesInput({gameId, updNotifications}) {

  const {register, resetField, handleSubmit} = useForm({defaultValues: {text: ""}})
  const spinner = useOutletContext().spinner[1]

  function sendNote(event) {
    event.preventDefault();
    if (event.keyCode === 13 && event.ctrlKey) {
      handleSubmit(onSubmit)()
    }
  }

  async function onSubmit(data) {
    if (!data.text) return
    if (data.text.trim() === "") {
      resetField("text")
      return
    }

    spinner({})
    const res = await SERVICE.addNote(gameId, data)
    if (res && res.status === 200) resetField("text")
    if (res && res.status === 400) {
      if (res.data === "Game closed") {
        notify(updNotifications, "error", message("notification.game.closed"))
      }
      resetField("text")
    }
    spinner(null)
  }

  return <>
    <div className="card shadow mx-1 my-0 py-1 bg-body-tertiary">
      <div className="card-body my-0 mx-1 py-1 px-1">
        <form className="needs-validation input-group shadow-sm" onSubmit={handleSubmit(onSubmit)}>
          <TextareaAutosize className="form-control border-1 border-dark"
                            placeholder={message("page.game.card.notes.form.placeholder")}
                            {...register("text")} autoComplete="off"
                            onKeyUp={e => sendNote(e)}
          />
          <button className="col-2 border-1 border-dark btn btn-secondary" type="submit">
            <i className="bi bi-send-fill"></i>
          </button>
        </form>
      </div>
    </div>
  </>

}