import axios from "axios";


const CONTENT_TYPE_JSON = {"Content-Type": "application/json"};
const CONTENT_TYPE_MULTIPART = {"Content-Type": "multipart/form-data"};

class Service {

  constructor() {
    this.token = localStorage.getItem("token")
  }

  async setToken(token) {
    localStorage.setItem("token", token)
    this.token = token
  };

  async doLogout() {
    localStorage.removeItem("token")
    this.token = ""
  }

  async doLogin(data) {
    const config = {
      headers: {
        ...CONTENT_TYPE_JSON
      }
    }
    return axios.post("/api/user/login", data, config).catch(handleError());
  }

  async doRegistration(data) {
    const config = {
      headers: {
        "Authorization": "Bearer " + this.token,
      }
    }
    return axios.post("/api/user/registration", data, config).catch(handleError());
  }

  async getUser() {
    const config = {
      headers: {
        "Authorization": "Bearer " + this.token,
      }
    }
    return axios.get("/api/user", config).catch(handleError());
  }

  async getAvailableResources() {
    const config = {
      headers: {
        "Authorization": "Bearer " + this.token,
      }
    }
    return axios.get("/api/resources", config).catch(handleError());
  }

  async createScenario(data) {
    const config = {
      headers: {
        "Authorization": "Bearer " + this.token,
        ...CONTENT_TYPE_JSON
      }
    }
    return axios.post("/api/scenarios", data, config).catch(handleError());
  }

  async getScenario(scenarioId) {
    const config = {
      headers: {
        "Authorization": "Bearer " + this.token,
      }
    }
    return axios.get("/api/scenarios/" + scenarioId, config).catch(handleError());
  }

  async getAvailableScenarios() {
    const config = {
      headers: {
        "Authorization": "Bearer " + this.token,
      },
      params: {
        "access": "available"
      }
    }
    return axios.get("/api/scenarios", config).catch(handleError());
  }

  async getEditableScenarios() {
    const config = {
      headers: {
        "Authorization": "Bearer " + this.token,
      },
      params: {
        "access": "editable"
      }
    }
    return axios.get("/api/scenarios", config).catch(handleError());
  }

  async getAvailableLobbies() {
    const config = {
      headers: {
        "Authorization": "Bearer " + this.token,
      }
    }
    return axios.get("/api/lobbies", config).catch(handleError());
  }

  async getAvailableGames() {
    const config = {
      headers: {
        "Authorization": "Bearer " + this.token,
      }
    }
    return axios.get("/api/games", config).catch(handleError());
  }

  async saveScenario(id, data) {
    const config = {
      headers: {
        "Authorization": "Bearer " + this.token,
        ...CONTENT_TYPE_JSON
      }
    }
    return axios.put("/api/scenarios/" + id, data, config).catch(handleError());
  }

  async createLobby(data) {
    const config = {
      headers: {
        "Authorization": "Bearer " + this.token,
        ...CONTENT_TYPE_JSON
      }
    }
    return axios.post("/api/lobbies", data, config).catch(handleError());
  }

  async getLobby(lobbyId) {
    const config = {
      headers: {
        "Authorization": "Bearer " + this.token,
      }
    }
    return axios.get("/api/lobbies/" + lobbyId, config).catch(handleError())
  }

  async doConnectLobby(lobbyId) {
    const config = {
      headers: {
        "Authorization": "Bearer " + this.token,
        ...CONTENT_TYPE_JSON
      }
    }
    return axios.post("/api/lobbies/" + lobbyId + "/players", null, config).catch(handleError());
  }

  async doBlockLobbyPlayer(lobbyId, username) {
    const config = {
      headers: {
        "Authorization": "Bearer " + this.token,
        ...CONTENT_TYPE_JSON
      }
    }
    return axios.patch("/api/lobbies/" + lobbyId + "/players/" + username + "/block", null, config).catch(handleError());
  }

  async doApproveLobbyPlayer(lobbyId, username) {
    const config = {
      headers: {
        "Authorization": "Bearer " + this.token,
        ...CONTENT_TYPE_JSON
      }
    }
    return axios.patch("/api/lobbies/" + lobbyId + "/players/" + username + "/approve", null, config).catch(handleError());
  }

  async createGame(data) {
    const config = {
      headers: {
        "Authorization": "Bearer " + this.token,
        ...CONTENT_TYPE_JSON
      }
    }
    return axios.post("/api/games", data, config).catch(handleError());
  }

  async getGame(gameId) {
    const config = {
      headers: {
        "Authorization": "Bearer " + this.token,
      }
    }
    return axios.get("/api/games/" + gameId, config).catch(handleError())
  }

  async getGamePlayers(gameId) {
    const config = {
      headers: {
        "Authorization": "Bearer " + this.token,
      }
    }
    return axios.get("/api/games/" + gameId + "/players", config).catch(handleError())
  }

  async getGameSections(gameId) {
    const config = {
      headers: {
        "Authorization": "Bearer " + this.token,
      }
    }
    return axios.get("/api/games/" + gameId + "/sections", config).catch(handleError())
  }

  async getGameNotes(gameId) {
    const config = {
      headers: {
        "Authorization": "Bearer " + this.token,
      }
    }
    return axios.get("/api/games/" + gameId + "/notes", config).catch(handleError())
  }

  async addNote(gameId, data) {
    const config = {
      headers: {
        "Authorization": "Bearer " + this.token,
        ...CONTENT_TYPE_JSON
      }
    }
    return axios.post("/api/games/" + gameId + "/notes", data, config).catch(handleError())
  }

  async addElement(gameId, data) {
    const config = {
      headers: {
        "Authorization": "Bearer " + this.token,
        ...CONTENT_TYPE_JSON
      }
    }
    return axios.post("/api/games/" + gameId + "/elements", data, config).catch(handleError())
  }

  async getNotificationToken() {
    const config = {
      headers: {
        "Authorization": "Bearer " + this.token,
        ...CONTENT_TYPE_JSON
      }
    }
    return await axios.get("/api/notifications/token", config).catch(handleError())
  }

  async getGameObject(gameId) {
    const config = {
      headers: {
        "Authorization": "Bearer " + this.token,
      }
    }
    return axios.get("/api/games/" + gameId + "/object", config).catch(handleError())
  }

  async updateGameObject(gameId, data) {
    const config = {
      headers: {
        "Authorization": "Bearer " + this.token,
        ...CONTENT_TYPE_JSON
      }
    }
    return axios.put("/api/games/" + gameId + "/object", data, config).catch(handleError())
  }

  async createResource(formData) {
    const config = {
      headers: {
        "Authorization": "Bearer " + this.token,
        ...CONTENT_TYPE_MULTIPART
      }
    }
    return axios.post("/api/resources", formData, config).catch(handleError())
  }

  async removeResource(id) {
    const config = {
      headers: {
        "Authorization": "Bearer " + this.token,
      }
    }
    return axios.delete("/api/resources/" + id, config).catch(handleError())
  }

  async removeNote(gameId, noteId) {
    const config = {
      headers: {
        "Authorization": "Bearer " + this.token,
      }
    }
    return axios.delete("/api/games/" + gameId + "/notes/" + noteId, config).catch(handleError())
  }
}

export const SERVICE = new Service();

function handleError() {
  return error => error.response
}