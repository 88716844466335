import React from "react";
import message from "../../message";

export function EditorTitle({register, title, share}) {

  return (
      <>
          <div className="mx-1 my-0 py-0 row">
              <label className="col-3 col-form-label text-center" htmlFor="scenario-name">
                  <h5>{message("page.editor.card.form.title")}</h5>
              </label>

              <div className="col-7">
                  <input {...register("name", {
                      required: true,
                      minLength: 5
                  })}
                         className="form-control shadow-sm" id="scenario-name"
                         type="text" defaultValue={title} required={true}
                  />
              </div>

              <div className="col-2 col-form-label text-center">
                  <input {...register("shared", {
                      required: false
                  })}
                         className="form-check-inline mx-1"
                         id={"share-checkbox"}
                         type={"checkbox"} title={"Share"}
                         checked={share}
                  />
                  <label className="mx-1" htmlFor="share-checkbox"><p>Share</p></label>
              </div>
          </div>
      </>
  );
}