import React, {useId, useState} from 'react';
import AuthCardTitle from "../components/auth/AuthCardTitle";
import message from "../message";
import {useNavigate, useOutletContext} from "react-router-dom";
import Container from "../components/common/Contatiner";
import {useForm} from "react-hook-form";
import {SERVICE} from "../service";
import Notifications from "../components/notifications/Notifications";
import notify from "../notify";

export default function LoginContainer() {
  const spinner = useOutletContext().spinner[1]
  const {register, handleSubmit} = useForm()
  const [notifications, updateNotifications] = useState([])
  const [mistakes, setMistakes] = useState(null)
  const navigate = useNavigate()

  async function onSubmit(data) {
    spinner({})
    const response = await SERVICE.doLogin(data)
    if (response && response.status === 200) {
      await SERVICE.setToken(response.data.token)
      spinner(null)
      return navigate("/");
    }

    if (response && response.status === 400) {
      notify(updateNotifications, "error", message("notification.validation.error"));
      setMistakes(response.data)
    }

    if (response && response.status === 401) {
      notify(updateNotifications, "error", message("notification.unauthorized.error"));
    }

    if (response && response.status === 500) {
      notify(updateNotifications, "error", message("notification.unknown.error"));
    }

    spinner(null)
  }

  return <>
    <Container>
      <div style={{width: "50lvw", maxWidth: "810px"}}>

        <div className="card my-3 mx-auto">
          <div className="row">

            <div className="col-5 mx-auto p-0">
              <img className="img-fluid rounded-start"
                   src="/img_login.jpg"
                   alt="Login immersive image"/>
            </div>

            <div className="col-7 mx-auto p-0">
              <AuthCardTitle current={"login"}/>

              <div className="card-body ms-0 ps-3">
                <form className="needs-validation mx-3" onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-3">
                    <input id={useId()} name={"username"} type={"text"}
                           className={"form-control shadow-sm"}
                           {...register("username", {required: true})}
                           placeholder={message("page.login.card.form.username.placeholder")}
                           autoComplete={"off"} required autoFocus
                    />
                  </div>
                  <div className="mb-3">
                    <input id={useId()} name={"password"} type={"password"}
                           className={"form-control shadow-sm"}
                           {...register("password", {required: true})}
                           placeholder={message("page.login.card.form.password.placeholder")}
                           autoComplete="off" required
                    />
                  </div>
                  <button type="submit" className="btn btn-success shadow">
                    {message("page.login.card.form.submit")}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Notifications notifications={notifications}/>
    </Container>
  </>
}

