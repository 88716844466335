import {SERVICE} from "../service";
import {redirect} from "react-router-dom";

export default async function gameLoader({params}) {
  try {
    const game = await SERVICE.getGame(params.gameId)

    if (game && game.status === 404)
      return redirect("/");

    const players = await SERVICE.getGamePlayers(params.gameId);
    const sections = await SERVICE.getGameSections(params.gameId);
    const notes = await SERVICE.getGameNotes(params.gameId);

    if ((players && sections && notes) && (players.status === 200 && sections.status === 200 && notes.status === 200)) {
      return {
        game: game.data,
        players: players.data,
        sections: sections.data,
        notes: notes.data
      };
    }
  } catch (e) {
    console.error(e);
  }
  return {
    game: {},
    players: {},
    sections: {},
    notes: {}
  };
}