import React, {useEffect, useState} from "react";
import {useLoaderData, useOutletContext} from "react-router-dom";
import Container from "../components/common/Contatiner";
import GameSidebar from "../components/game/sidebar/GameSidebar";
import GamePanel from "../components/game/GamePanel";
import {SERVICE} from "../service";
import Notifications from "../components/notifications/Notifications";
import message from "../message";
import notify from "../notify";

export default function GameContainer() {
  const spinner = useOutletContext().spinner[1]
  const user = useOutletContext().user;
  let eventSource = useOutletContext().eventSource;

  const [game] = useState(useLoaderData().game)
  const [players] = useState(useLoaderData().players)
  const [master, updMaster] = useState(false)

  const [sections, updSections] = useState(useLoaderData().sections)
  const [notes, updNotes] = useState(useLoaderData().notes)

  const [notifications, updNotifications] = useState([])

  const updModal = useOutletContext().updModal

  useEffect(() => {
    if ((players) && user) {
      updMaster(players.filter(player => player.username === user.username)[0].master)
    }
  }, [players])

  useEffect(() => {
    if (eventSource && !eventSource.onmessage) {

      eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);

        console.debug("New event:", data)

        if (data.code === "notification.game.elements.exist") {
          notify(updNotifications, "error", message("notification.game.element.exist"))
        }

        if (data.code === "notification.game.elements.notfound") {
          notify(updNotifications, "error", message("notification.game.element.notfound"))
        }

        if (data.code === "notification.game.update") {
          notify(updNotifications, "error", message("notification.game.update"))
          window.setTimeout(() => window.location.reload(false), 5500)
        }

        if (data.code === "notification.game.elements.add") {
          notify(updNotifications, "game", message("notification.game.element.add", null, [data.attachments.elementName, data.attachments.username, data.attachments.sectionName]))

          spinner({})

          SERVICE.getGameSections(game.id)
            .then(res => {
              if (res.status === 200) {
                updSections(res.data)
              }
            })

          spinner(null)
        }

        if (data.code === "notification.game.notes.add") {
          notify(updNotifications, "notes", message("notification.game.note.added", null, [data.attachments.username]));

          spinner({})

          SERVICE.getGameNotes(game.id)
            .then(res => {
              if (res.status === 200) {
                updNotes(res.data)
              }
            })

          spinner(null)
        }

        if (data.code === "notification.game.notes.update" || data.code === "notification.game.notes.remove") {
          if (data.code === "notification.game.notes.remove") {
            notify(updNotifications, "notes", message("notification.game.note.removed", null, [data.attachments.username]));
          }

          if (data.code === "notification.game.notes.update") {
            notify(updNotifications, "notes", message("notification.game.note.updated", null, [data.attachments.username]));
          }

          spinner({})

          SERVICE.getGameNotes(game.id)
            .then(res => {
              if (res.status === 200) {
                updNotes(res.data)
              }
            })

          spinner(null)
        }
      }

    }
    return () => {
    }
  }, [eventSource])

  return <>
    <Container>
      <div className="row justify-content-evenly">
        <GameSidebar game={game} players={players} notes={notes} updNotifications={updNotifications}/>
        <div className="col-8 col-xxl-7">
          <GamePanel game={game} master={master} sections={sections} updNotifications={updNotifications}
                     updModal={updModal}/>
        </div>
      </div>
      <Notifications notifications={notifications}/>
    </Container>
  </>
}
