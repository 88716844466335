import React, {useEffect, useState} from "react";
import GamePanelElements from "./GamePanelElements";
import GamePanelControl from "./GamePanelControl";

export default function GamePanel({game, master, sections, updNotifications, updModal}) {

  const [tab, updateTab] = useState(sections[0].key);
  const [elements, updateElements] = useState(sections[0].elements);

  async function switchTab(key) {
    console.debug("switch tab to:" + key)
    updateElements(sections.filter(section => section.key === key)[0].elements)
    updateTab(key)
  }

  useEffect(() => {
    if (sections && tab) {
      updateElements(sections.filter(section => section.key === tab)[0].elements)
    }
  }, [sections])

  return <>
    <main role="main">
      <GamePanelControl game={game} master={master} sections={sections}
                        tab={tab} switchTab={switchTab} updNotifications={updNotifications}/>

      <GamePanelElements elements={elements} updModal={updModal}/>
    </main>
  </>
}