import React from "react";
import message from "../../../message";

export default function GameSidebarPlayers({players}) {
  return <>
    <div className="card shadow mx-1 my-3 py-1 bg-body-tertiary">
      <div className="card-title mt-1 mb-0 mx-3">
        <h3 className="text-decoration-none fw-bold">{message("page.game.card.players.title")}</h3>
      </div>
      <div className="card-body my-0 mx-1 py-1 px-1">
        <ul className="list-group my-1">
          {
            players && players.length > 0
              ? players.map(player =>
                <li key={player.username} className="list-group-item my-0 px-1 py-1 shadow-sm">
                                            <span className="align-center">
                                                {
                                                  player.master
                                                    ?  <i className="bi bi-house-door-fill"></i>
                                                    : <i className="bi bi-person-circle"></i>
                                                }
                                            </span>
                  <span className="fs-5 fw-bolder ms-2">{player.username}</span>
                </li>
              )
              : <></>
          }
        </ul>
      </div>
    </div>
  </>
}