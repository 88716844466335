import React from "react";
import message from "../../message";
import TextareaAutosize from 'react-textarea-autosize';

export function ElementInput({index, register, element, remove, sectionList, resourceList}) {

  const text = {
    elements: {
      name: message("page.editor.card.form.elements.field.name"),
      title: message("page.editor.card.form.elements.field.title"),
      key: message("page.editor.card.form.elements.field.key"),
      section: message("page.editor.card.form.elements.field.section"),
      resource: message("page.editor.card.form.elements.field.resource"),
      order: message("page.editor.card.form.elements.field.order"),
      text: message("page.editor.card.form.elements.field.text"),
      access: message("page.editor.card.form.elements.field.access"),
    },
  }

  return (
      <>
        <div className="card shadow-sm w-100 my-3 element">
          <div className="card-body">
            <div>
              <div className="input-group my-1">
                <span className="input-group-text px-2 сol-auto">{text.elements.name}</span>
                <input {...register("elements." + index + ".name", {
                  required: true
                })}
                       className="form-control" type="text" name={"elements." + index + ".name"}
                       value={element.name} required={true}
                />

                <span className="input-group-text px-2 сol-auto">{text.elements.title}</span>
                <input {...register("elements." + index + ".title", {
                  required: true
                })}
                       className="form-control" name={"elements." + index + ".title"} type="text"
                       defaultValue={element.title} required={true}
                />

                <button className="btn btn-outline-danger" type="button" onClick={() => remove(element.key)}>
                  <i className="bi bi-dash-circle"></i>
                </button>
              </div>

              <div className="input-group my-1">
                <span className="input-group-text px-2 col-auto">{text.elements.key}</span>
                <input {...register("elements." + index + ".key", {
                  required: true
                })}
                       className="form-control сol-auto" name={"elements." + index + ".key"} type="text"
                       defaultValue={element.key} required={true}/>

                <span className="input-group-text px-2 col-auto">{text.elements.section}</span>
                <select {...register("elements." + index + ".sectionKey", {
                  required: true
                })}
                        className="form-select сol-auto" name={"elements." + index + ".sectionKey"}
                        defaultValue={element.sectionKey} required={true}>
                  <option key={""} value="">{"--"}</option>
                  {
                    sectionList.map(section =>
                        <option key={section.key} value={section.key}>
                          {section.name}
                        </option>
                    )
                  }
                </select>
              </div>

              <div className="input-group my-1">
                <span className="input-group-text px-2 col-auto">{text.elements.order}</span>
                <input {...register("elements." + index + ".order", {
                  required: false
                })}
                       className="form-control сol-auto" name={"elements." + index + ".order"} type="number"
                       defaultValue={element.order}
                />

                <span className="input-group-text px-2 col-auto">{text.elements.access}</span>
                <select {...register("elements." + index + ".access", {
                  required: true
                })}
                        className="form-select сol-auto" name={"elements." + index + ".access"}
                        defaultValue={element.access} required={true}
                >
                  <option key={"ALWAYS"} value={"ALWAYS"}>
                    {message("page.editor.card.form.elements.field.access.always")}
                  </option>
                  <option key={"BY_REQUEST"} value={"BY_REQUEST"}>
                    {message("page.editor.card.form.elements.field.access.request")}
                  </option>
                </select>
              </div>

              <div className="input-group my-1">
                <span className="input-group-text px-2 col-auto">{text.elements.resource}</span>
                <select {...register("elements." + index + ".resourceId", {
                  required: false
                })}
                        className="form-select " name={"elements." + index + ".resourceId"}
                        defaultValue={element.resourceId}
                >
                  <option key={""} value="">{"--"}</option>
                  {
                    resourceList.map(resource =>
                        <option key={resource.id} value={resource.id}>
                          {resource.name}
                        </option>
                    )
                  }
                </select>
              </div>

              <div className="input-group my-1">
                <span className="input-group-text px-2 col-auto">{text.elements.text}</span>
                <TextareaAutosize {...register("elements." + index + ".text", {
                  required: false
                })}
                                  className="form-control" name={"elements." + index + ".text"}
                                  value={element.text}
                />
              </div>
            </div>
          </div>
        </div>
      </>
  )
}