import Card from "../../common/Card";
import React, {Fragment} from "react";
import message from "../../../message";

export default function GameSidebarTitle({game}) {
  return (
    <>
      {
        game.close ?
          <Card>
            <p className="fs-3 fw-bolder text-decoration-line-through">
              {game.scenarioName}
            </p>
            <p className="fs-6 fw-light fst-italic text-center text-decoration-underline">
              {message("page.game.card.scenario.title.close")}
            </p>
          </Card>
          :
          <Card>
            <p className="fs-3 fw-bolder">{game.scenarioName}</p>
          </Card>
      }
    </>
  );
}