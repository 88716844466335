import React from "react";
import {useLoaderData, useNavigate} from "react-router-dom";
import {SERVICE} from "../service";
import Container from "../components/common/Contatiner";
import StartCard from "../components/StartCard";
import ListCardItem from "../components/ListCardItem";

export default function StartContainer() {
  const navigate = useNavigate()

  const scenarios = useLoaderData().scenarios
  const lobbies = useLoaderData().lobbies
  const games = useLoaderData().games

  async function start(scenarioId) {
    try {
      const res = await SERVICE.createLobby(JSON.stringify({scenarioId: scenarioId}))
      if (res && res.status === 200) {
        navigate("/lobby/" + res.data.id)
      }
    } catch (e) {
      console.error(e)
      navigate("/")
    }
  }

  async function toLobby(lobbyId) {
    navigate("/lobby/" + lobbyId)
  }

  async function toGame(gameId) {
    navigate("/game/" + gameId)
  }

  return <>
    <Container>
      <div style={{width: "50lvw", maxWidth: "810px"}}>
        {
          lobbies && lobbies.length > 0 ?
            <StartCard type={"lobbies"} items={lobbies}
                       itemMapper={lobby =>
                         <ListCardItem key={lobby.id} onClick={() => toLobby(lobby.id)} text={lobby.scenarioName}/>
                       }
            />
            : <></>
        }
        {
          games && games.length > 0 ?
            <StartCard type={"games"} items={games}
                       itemMapper={game =>
                         <ListCardItem key={game.id} onClick={() => toGame(game.id)} text={game.scenarioName}/>
                       }
            />
            : <></>
        }
        <StartCard type={"scenarios"} items={scenarios}
                   itemMapper={scenario =>
                     <ListCardItem key={scenario.id} onClick={() => start(scenario.id)} text={scenario.name}/>
                   }
        />
      </div>
    </Container>
  </>
}
